import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import facebook from "../images/footer/facebook.png";
import twitter from "../images/footer/twitter.png";
import youtube from "../images/footer/youtube.png";
import instagram from "../images/footer/instagram.png";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyName
          author
        }
      }
    }
  `);

  return (
    <footer className="footer">
      <div className="container">
        <div className="row text-center">
          <div className="col">
            <a href="https://m.facebook.com/wildfiregamesuk/">
              <img className="col-img" src={facebook} alt="Facebook" />
            </a>
          </div>
          <div className="col">
            <a href="https://www.instagram.com/wildfiregamesuk/">
              <img className="col-img" src={instagram} alt="Instagram" />
            </a>
          </div>
          <div className="col">
            <a href="https://twitter.com/wildfiregamesuk">
              <img className="col-img" src={twitter} alt="Twitter" />
            </a>
          </div>
          <div className="col">
            <a href="https://youtube.com/channel/UC9fB0JsvHenxrERcOzCi8SQ">
              <img className="col-img" src={youtube} alt="YouTube" />
            </a>
          </div>
        </div>
        <p className="mt-5">
          Created by {data.site.siteMetadata.author}, ©{" "}
          {data.site.siteMetadata.companyName} {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
