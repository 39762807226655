import * as React from "react"
import { Helmet } from "react-helmet"

import Header from './header'
import Footer from './footer'

import layoutStyles from "../styles/layout.module.scss"
import {withPrefix} from "gatsby";

const Layout = (props) => {
    return(
        <div className={layoutStyles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Wildfire Games
                </title>
                <link href={withPrefix("animate.min.css")} rel="stylesheet" />
                <script src={withPrefix("josh.min.js")} type="text/javascript" />
                <script src={withPrefix("lax.min.js")} type="text/javascript" />
                <script src={withPrefix("overrun.js")} type="text/javascript" />
                <script src={withPrefix("orbit-drift.js")} type="text/javascript" />
            </Helmet>
            <Header />
            <div className={layoutStyles.content}>
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout