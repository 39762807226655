import * as React from "react";
import Link from "gatsby-link";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";

import logo from "../images/logo/logo-header-white.png";
import OverrunIcon from "../images/overrun/icon-128.png";
import OrbitDriftIcon from "../images/orbit-drift/icon-128.png";

function isPath(path) {
  return false;
  // return window.location.pathname === path;
}

const Header = (pathname) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header>
      <Navbar bg="primary" variant="dark" expand="lg">
        <Navbar.Brand href="/">
          <img src={logo} alt={data.site.siteMetadata.title} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar"></Navbar.Toggle>
        <Navbar.Collapse id="navbar">
          <Nav className="ml-auto">
            <Nav.Item>
              <Link
                className={"nav-link" + (isPath("/") ? " active" : "")}
                to="/"
              >
                Home
              </Link>
            </Nav.Item>
            <NavDropdown title="Games" id="nav-dropdown">
              <Nav.Item>
                <Link
                    className={
                      "dropdown-item" + (isPath("/overrun") ? " active" : "")
                    }
                    to="/overrun"
                >
                  <img
                      className="navbar-app-icon"
                      src={OverrunIcon}
                      alt={"Overrun! icon"}
                  />
                  Overrun!
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                    className={
                      "dropdown-item" + (isPath("/orbit-drift") ? " active" : "")
                    }
                    to="/orbit-drift"
                >
                  <img
                      className="navbar-app-icon"
                      src={OrbitDriftIcon}
                      alt={"Orbit Drift icon"}
                  />
                  Orbit Drift
                </Link>
              </Nav.Item>
            </NavDropdown>
            <Nav.Item>
              <Link
                className={"nav-link" + (isPath("/contact") ? " active" : "")}
                to="/contact"
              >
                Contact
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className={
                  "nav-link" + (isPath("/privacy-policy") ? " active" : "")
                }
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
